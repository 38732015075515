import { HeartSwitch } from "@anatoliygatt/heart-switch";
import { Person } from "../../models/person.model";
import styles from "./AdminCard.module.css";
import { Group } from "../../models/group.model";
import { Card, CardContent, Typography } from "@mui/material";

interface Props {
  group: Group;
}

const AdminCard = ({ group }: Props) => {
  const respondDate = group.respondDate
    ? new Date(group.respondDate).toLocaleString("hu-HU")
    : "-";
  const updateDate = group.updated
    ? new Date(group.updated).toLocaleString("hu-HU")
    : "-";
  return (
    <Card sx={{ minWidth: 330 }} className={styles.card}>
      <CardContent>
        <Typography variant="h5" component="div">
          {group.addressing} {group.groupName}
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
          ID: {group.publicId}
        </Typography>
        <Typography variant="body2" component="div">
          <div className={styles.input_container}>
            <label className={styles.toggle_label}>Visszajelzett</label>
            <HeartSwitch
              size="sm"
              checked={group.responded}
              disabled={true}
              disabledTrackFillColor={group.responded ? "#4caf50" : "#f44336"}
            />
          </div>
        </Typography>
        <Typography variant="body2" component="div">
          <div className={styles.input_container}>
            <label>
              {group.people.length > 1 ? "Ott leszünk: " : "Ott leszek: "}
            </label>
            <HeartSwitch
              size="sm"
              checked={group.coming}
              disabled={true}
              disabledTrackFillColor={group.coming ? "#4caf50" : "#f44336"}
            />
          </div>
          <div className={styles.input_container}>
            <label className={styles.toggle_label}>
              {group.people.length > 1
                ? "Szeretnénk szállást: "
                : "Szeretnék szállást:"}
            </label>
            <HeartSwitch
              size="sm"
              checked={group.accomodation}
              disabled={true}
              disabledTrackFillColor={
                group.accomodation ? "#4caf50" : "#f44336"
              }
            />
          </div>
        </Typography>
        <hr className={styles.hr} />
        <Typography variant="body2" component="div">
          {group.people?.map((person: Person) => (
            <div className={styles.input_container} key={person?.publicId}>
              <label className={styles.text_label}>{person?.nickname}:</label>
              {person?.foodIntolerances}
            </div>
          ))}
        </Typography>
        <hr className={styles.hr} />
        <Typography variant="body2" component="div">
          <div className={styles.input_container}>
            <label className={styles.toggle_label}>Visszajelzés ideje</label>
            <div>{respondDate}</div>
          </div>
        </Typography>
        <Typography variant="body2" component="div">
          <div className={styles.input_container}>
            <label className={styles.toggle_label}>Frissítés ideje</label>
            <div>{updateDate}</div>
          </div>
        </Typography>
        <Typography variant="body2" component="div">
          <div className={styles.input_container}>
            <label className={styles.toggle_label}>Megtekintések</label>
            <div>{group.requests}</div>
          </div>
        </Typography>
      </CardContent>
    </Card>
    // <div className={styles.group} key={group.publicId}>
    //   <div className={`${styles.title} section-title`}>{group.groupName}</div>
    //   <div className={styles.group}>
    //     <img className="title-divider" alt="" src="./divider.svg" />
    //     <form className={`${styles.form}`}>
    //       <div className={styles.input_container}>
    //         <label className={styles.toggle_label}>
    //           {group.people.length > 1 ? "Ott leszünk: " : "Ott leszek: "}
    //         </label>
    //         <HeartSwitch size="sm" checked={group.coming} />
    //       </div>
    //       <div className={styles.input_container}>
    // <label className={styles.toggle_label}>
    //   {group.people.length > 1
    //     ? "Szeretnénk szállást*: "
    //     : "Szeretnék szállást*:"}
    // </label>
    // <HeartSwitch size="sm" checked={group.accomodation} />
    //       </div>
    //       <hr />
    //       <div className={`${styles.food_intolerance_container} subtitle`}>
    //         Ételintolerancia/allergia
    //       </div>
    //   {group.people?.map((person: Person) => (
    //     <div className={styles.input_container} key={person.publicId}>
    //       <label className={styles.text_label}>{person.nickname}:</label>
    //       {person?.foodIntolerances}
    //     </div>
    //   ))}
    //     </form>
    //     <p>{group.accomodation}</p>
    //   </div>
    // </div>
  );
};

export default AdminCard;
