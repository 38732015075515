import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Group } from "../../models/group.model";
import styles from "./Admin.module.css";
import axios from "axios";
import AdminCard from "./AdminCard";
import { Card, CardContent, Typography } from "@mui/material";

const Admin = () => {
  const { secret } = useParams();
  const navigate = useNavigate();
  const [groups, setGroups] = useState<Group[]>({} as Group[]);

  useEffect(() => {
    const fetchData = async () => {
      await axios
        .get<Group[]>(`/api/groups/${secret}`)
        .then((res) => {
          setGroups(res.data);
        })
        .catch(() => {
          navigate("/404");
        });
    };
    fetchData();
  }, [secret, navigate]);

  return (
    <section className="app-bg">
      <div className={`${styles.title} section-title`}>Visszajelzések</div>
      <Card sx={{ minWidth: 230 }} className={styles.card}>
        <CardContent>
          <Typography variant="body2" component="div">
            <div className={styles.input_container}>
              <label className={styles.toggle_label}>Csoport</label>
              <div>{groups.length}</div>
            </div>
          </Typography>
          <Typography variant="body2" component="div">
            <div className={styles.input_container}>
              <label className={styles.toggle_label}>Visszajelzett</label>
              <div>
                {groups.length > 0 &&
                  groups.reduce((a, b) => a + (b.responded ? 1 : 0), 0)}
              </div>
            </div>
          </Typography>
          <hr className={styles.hr} />
          <Typography variant="body2" component="div">
            <div className={styles.input_container}>
              <label className={styles.toggle_label}>Fő</label>
              <div>
                {groups.length > 0 &&
                  groups
                    .map((group) => group.people.length)
                    .reduce((a, b) => a + b)}
              </div>
            </div>
          </Typography>
          <Typography variant="body2" component="div">
            <div className={styles.input_container}>
              <label className={styles.toggle_label}>Jön</label>
              <div>
                {groups.length > 0 &&
                  groups.reduce((a, b) => a + (b.coming ? 1 : 0), 0)}
              </div>
            </div>
          </Typography>
          <Typography variant="body2" component="div">
            <div className={styles.input_container}>
              <label className={styles.toggle_label}>Kér szállást</label>
              <div>
                {groups.length > 0 &&
                  groups.reduce(
                    (a, b) => a + (b.accomodation ? 1 * b.people.length : 0),
                    0
                  )}
              </div>
            </div>
          </Typography>
          <hr className={styles.hr} />
          <Typography variant="body2" component="div">
            <div className={styles.input_container}>
              <label className={styles.toggle_label}>Lekérések száma</label>
              <div>
                {groups.length > 0 &&
                  groups.reduce((a, b) => a + (b.requests ? 1 : 0), 0)}
              </div>
            </div>
          </Typography>
        </CardContent>
      </Card>
      {groups.length > 0 && (
        <div className={styles.container}>
          {groups.map((group) => (
            <AdminCard group={group} key={group.publicId} />
          ))}
        </div>
      )}
    </section>
  );
};

export default Admin;
