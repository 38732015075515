import styles from "./Beverage.module.css";

const Beverage = () => {
  return (
    <div id="beverage">
      <section>
        <div className="section-container">
          <div className={styles.menu_container}>
            <div className={`${styles.title} section-title`}>Itallap</div>
            <img className="title-divider" alt="" src="divider.svg" />
            <div className={styles.drinks_container}>
              <div className={styles.drink_container}>
                <div className={`${styles.drink_title} title`}>
                  Alkoholmentes italok
                </div>
                <div className={styles.drink_description}>
                  <div className={styles.drink_name}>
                    Limonádé, házi szörpök
                  </div>
                  <div className={styles.drink_name}>
                    Szénsavas és szénsavmentes víz
                  </div>
                  <div className={styles.drink_name}>
                    Szénsavas és rostos üdítő (Coca, Pepsi és Rauch)
                  </div>
                  <div className={`${styles.drink_title} title`}>
                    Alkoholos italok
                  </div>
                  <div className={styles.drink_name}>
                    Csapolt sör (Soproni, Heineken és Belga meggy)
                  </div>
                  <div className={styles.drink_name}>Whiskey</div>
                  <div className={styles.drink_name}>Jägermeister</div>
                  <div className={styles.drink_name}>Unicum</div>
                  <div className={styles.drink_name}>Finlandia vodka</div>
                  <div className={styles.drink_name}>Bacardi Blanca Rum</div>
                  <div className={styles.drink_name}>Gordon's Gin</div>
                  <div className={styles.drink_name}>Borok (Fehér, Rose)</div>
                  <div className={styles.drink_name}>Pálinka </div>
                  <div className={`${styles.drink_title} title`}>Kávé</div>
                  <div className={styles.drink_name}>Kávé, cappuccino</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Beverage;
