import { useEffect, useState } from "react";
import "./App.css";
import Hero from "./components/hero/Hero";
import Sidebar from "./components/sidebar/Sidebar";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { Group } from "./models/group.model";
import RSVPForm from "./components/form/Form";
import Programs from "./components/programs/Programs";
import Venue from "./components/venue/Venue";
import Food from "./components/menu/food/Food";
import Beverage from "./components/menu/beverage/Beverage";

function App() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [group, setGroup] = useState<Group>({} as Group);

  useEffect(() => {
    const fetchData = async () => {
      await axios
        .get(`api/group/${id}`)
        .then((res) => {
          setGroup(res.data);
        })
        .catch(() => {
          navigate("/404");
        });
    };
    fetchData();
  }, [id, navigate]);

  return (
    <>
      {group.publicId && (
        <div className="app-bg">
          <Sidebar />
          <Hero group={group} />
          <RSVPForm group={group} />
          <Venue />
          <Food />
          <Beverage />
          <Programs />
        </div>
      )}
    </>
  );
}

export default App;
