import SchoolPride from "../confetti/SchoolPride";
import styles from "./Home.module.css";
import FlipClockCountdown from "@leenguyen/react-flip-clock-countdown";
import "@leenguyen/react-flip-clock-countdown/dist/index.css";
import { useState, useEffect } from "react";

const Home = () => {
  const [width, setWidth] = useState(35);
  const [height, setHeight] = useState(70);
  const weddingDate = new Date("2024-04-27T15:00:00+02:00");

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setWidth(30);
        setHeight(60);
      } else {
        setWidth(40);
        setHeight(60);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div className={styles.home}>
      <img className={styles.img} alt="" src="doriesgabor_cr.jpg" />
      <div className={styles.homeTextContainer}>
        <h1>Dóri és Gábor</h1>
      </div>
      <FlipClockCountdown
        to={weddingDate.getTime()}
        digitBlockStyle={{
          width: `${width}px`,
          height: `${height}px`,
          backgroundColor: "rgba(255, 112, 143, 1)",
        }}
      />
      <SchoolPride />
    </div>
  );
};

export default Home;
