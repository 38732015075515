import { useForm } from "react-hook-form";
import { Group } from "../../models/group.model";
import styles from "./Form.module.css";
import { Person } from "../../models/person.model";
import { useEffect, useState } from "react";
import { HeartSwitch } from "@anatoliygatt/heart-switch";
import axios from "axios";
import Confetti from "../confetti/Confetti";
import CustomizedDialogs from "../dialog/Dialog";
import { scroller } from "react-scroll";

interface Props {
  group?: Group;
}

const RSVPForm: React.FC<Props> = ({ group }: Props) => {
  const { register, handleSubmit } = useForm();
  const [coming, setComing] = useState<boolean>(false);
  const [accomodation, setAccomodation] = useState<boolean>(false);
  const [sending, setSending] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [fail, setFail] = useState<boolean>(false);
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  useEffect(() => {
    if (group?.accomodation !== undefined && group?.coming !== undefined) {
      setComing(group.coming);
      setAccomodation(group.accomodation);
    }
  }, [group]);

  const setComingState = (checked: boolean) => {
    setComing(checked);
    if (!checked) {
      setAccomodation(false);
    }
  };

  const toggleDialog = () => {
    setOpenDialog(!openDialog);
  };

  const getButtonClass = () => {
    let buttonClass = styles.submit_button;
    if (success) buttonClass += ` ${styles.success}`;
    if (fail) buttonClass += ` ${styles.fail}`;
    return buttonClass;
  };

  const getButtonText = () => {
    if (sending) return "💌 Küldés...";
    if (success && coming) return "🍾 Köszönjük!";
    if (success) return "Köszönjük!";
    if (fail) return "🚨 Hiba történt";
    if (coming) return "🥳 Elküld";
    return "😢 Elküld";
  };

  const handleSetActive = (to: string) => {
    scroller.scrollTo(to, {
      duration: 1000,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  };

  const onSubmit = (data: any) => {
    setSending(true);

    const groupDTO = {
      ...group,
      people: group?.people?.map((person: Person) => ({
        ...person,
        foodIntolerances: data.people[person.publicId].foodIntolerances,
      })),
      accomodation: coming ? accomodation : false,
      coming: coming,
    };

    axios.post(`api/group/${group?.publicId}`, groupDTO).then((res) => {
      setSending(false);
      if (res.status === 200) {
        if (coming) setIsVisible(true);
        setSuccess(true);
        setFail(false);
        setTimeout(() => {
          handleSetActive("venue");
        }, 2000);
      } else {
        setFail(true);
        setSuccess(false);
      }
    });
  };

  const getStyleForNumberOfGroup = () => {
    if (group?.people.length === 1) return styles.one_person;
    if (group?.people.length === 2) return styles.two_people;
    if (group?.people.length === 3) return styles.three_people;
    if (group?.people.length === 4) return styles.four_people;
  };

  return (
    <div id="rsvp">
      <section>
        {group && (
          <div className={styles.group}>
            <div className={`${styles.title} section-title`}>Visszajelzés</div>
            <img className="title-divider" alt="" src="divider.svg" />
            <form
              className={`${styles.form} ${getStyleForNumberOfGroup()}`}
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className={styles.input_container}>
                <label className={styles.toggle_label}>
                  {group.people.length > 1 ? "Ott leszünk: " : "Ott leszek: "}
                </label>
                <HeartSwitch
                  size="sm"
                  disabled={success}
                  checked={coming}
                  onChange={(event) => {
                    setComingState(event.target.checked);
                  }}
                />
              </div>
              <div className={styles.input_container}>
                <label className={styles.toggle_label}>
                  {group.people.length > 1
                    ? "Szeretnénk szállást*: "
                    : "Szeretnék szállást*:"}
                </label>
                <HeartSwitch
                  size="sm"
                  disabled={!coming || success}
                  checked={coming ? accomodation : false}
                  onChange={(event) => {
                    setAccomodation(event.target.checked);
                  }}
                />
              </div>
              <div className={styles.accomodation}>
                *A hotel rajtunk keresztül kedvezményes szállás lehetőséget
                biztosít (12.000Ft/fő)
                <p>
                  <button
                    type="button"
                    className={styles.venue_details}
                    onClick={() => toggleDialog()}
                  >
                    További részletek
                  </button>
                  <CustomizedDialogs
                    title="Szállás"
                    isOpen={openDialog}
                    toggleDialog={toggleDialog}
                    content=""
                  />
                </p>
              </div>
              <hr />
              <div className={`${styles.food_intolerance_container} subtitle`}>
                Ételintolerancia/allergia
              </div>
              {group.people?.map((person: Person) => (
                <div className={styles.input_container} key={person.publicId}>
                  <label className={styles.text_label}>
                    {person.nickname}:
                  </label>
                  <input
                    disabled={!coming || success}
                    className={styles.text_input}
                    defaultValue={person.foodIntolerances}
                    type="text"
                    {...register(`people.${person?.publicId}.foodIntolerances`)}
                  />
                </div>
              ))}
              {isVisible && <Confetti />}
              <button
                className={getButtonClass()}
                type="submit"
                disabled={success}
              >
                {getButtonText()}
              </button>
            </form>
            <p>{group.accomodation}</p>
          </div>
        )}
      </section>
    </div>
  );
};

export default RSVPForm;
