import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import styles from "./Dialog.module.css";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

interface Props {
  title: string;
  content: string;
  isOpen: boolean;
  toggleDialog: any;
}

const CustomizedDialogs: React.FC<Props> = ({
  title,
  content,
  isOpen,
  toggleDialog,
}: Props) => {
  return (
    <BootstrapDialog
      onClose={toggleDialog}
      aria-labelledby="customized-dialog-title"
      open={isOpen}
      className={styles.dialog}
    >
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        Szállóvendégeknek:
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={toggleDialog}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <div>
          A <strong>szállás 15:00-tól</strong> foglalható el.
        </div>
        <div>
          <strong>Reggeli</strong> másnap <strong>09:00-11:00-ig</strong>.
        </div>
        <hr className={styles.hr} />
        <div>
          Ha nincs másnap rendezvény, akkor a szauna és jakuzzi egész nap
          használható, valamint a kijelentkezés is lehet rugalmas.
        </div>
        <hr className={styles.hr} />
        <div>
          A gyerekeknek <strong>3 éves korig ingyenes</strong>,{" "}
          <strong>3-18 éves korig</strong> a szállásdíjat mi álljuk.
        </div>
      </DialogContent>
      <DialogActions></DialogActions>
    </BootstrapDialog>
  );
};

export default CustomizedDialogs;
