import { useState, useEffect } from "react";
import styles from "./Sidebar.module.css";
import Links from "./links/Links";
import ToggleButton from "./toggleButton/ToggleButton";
import { motion } from "framer-motion";

const Sidebar = () => {
  const [open, setOpen] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const isMobile = width <= 768;

  const variants = {
    open: {
      clipPath: isMobile
        ? "circle(1300px at 24px 24px)"
        : "circle(1300px at 50px 50px)",
      transition: { type: "spring", stiffness: 40 },
    },
    closed: {
      clipPath: isMobile
        ? "circle(20px at 24px 24px)"
        : "circle(40px at 50px 50px)",
      transition: { delay: 0.3, type: "spring", stiffness: 400, damping: 40 },
    },
  };

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [isMobile]);

  return (
    <motion.div className={styles.sidebar} animate={open ? "open" : "closed"}>
      <motion.div className={styles.bg} variants={variants}>
        <Links setOpen={setOpen} isMobile={isMobile} />
      </motion.div>
      <ToggleButton setOpen={setOpen} />
    </motion.div>
  );
};

export default Sidebar;
