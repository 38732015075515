import styles from "./Programs.module.css";

const Programs = () => {
  return (
    <div id="timeline">
      <section>
        <div className="section-container">
          <div className={styles.programs_container}>
            <div className={`${styles.title} section-title`}>Forgatókönyv</div>
            <img
              className={`${styles.title_divider} title-divider`}
              alt=""
              src="divider.svg"
            />
            <div className={styles.programs_list}>
              <span>16:30 - Vendégvárás</span>
              <span>17:00 - Ceremónia</span>
              <span>17:30 - Gratulációk</span>
              <span>19:00 - Ünnepi vacsora</span>
              <span>20:30 - Nyitótánc</span>
              <span>22:00 - Torta</span>
              <span>23:30 - Menyasszonytánc</span>
              <span>00:00 - Buli hajnalig</span>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Programs;
