import styles from "./Venue.module.css";

const Venue = () => {
  return (
    <div id="venue">
      <section>
        <div className={`${styles.title} section-title`}>Helyszín</div>
        <img className="title-divider" alt="" src="divider.svg" />
        <div className="section-container">
          <div className={styles.venue_container}>
            <div className={styles.content_container}>
              <div className={`${styles.title} title`}>Session Hotel</div>
              <img
                className={styles.img}
                src="session_hotel.jpg"
                alt="session hotel"
              />
              <div className={styles.address}>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.google.com/maps/place/Session+Hotel+R%C3%A1ckeve/@47.1808848,18.9411058,17z/data=!4m9!3m8!1s0x4741f7985f79623f:0xb78b278f1d92bffd!5m2!4m1!1i2!8m2!3d47.1811659!4d18.9439968!16s%2Fg%2F1thl462_?entry=ttu"
                >
                  📍 Cím:{" "}
                  <span className={styles.address_link}>
                    2300 Ráckeve, Termál sétány 1.
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Venue;
