import { Group } from "../../models/group.model";
import styles from "./Hero.module.css";

interface Props {
  group?: Group;
}

const Hero: React.FC<Props> = ({ group }: Props) => {
  return (
    <div id="home">
      <section className={styles.group_container}>
        {group && (
          <div className={styles.group_subcontainer}>
            <div className={styles.group}>
              <h1 className={`${styles.group_title} title`}>
                {group.addressing} {group.groupName}!
              </h1>
              <img className={styles.img} alt="" src="doriesgabor_cr.jpg" />
              <div className={`${styles.subtitle} subtitle`}>
                Sok szeretettel meghívunk, hogy velünk ünnepeljetek, mikor{" "}
                <strong>2024. április 27</strong>-én 17:00 órakor végleg
                összekötjük életünket.
              </div>
            </div>
          </div>
        )}
      </section>
    </div>
  );
};

export default Hero;
