import { motion } from "framer-motion";
import styles from "./Links.module.css";
import { Link } from "react-scroll";

const Links = ({ setOpen }: any) => {
  const variants = {
    open: {
      transition: {
        staggerChildren: 0.15,
      },
    },
    closed: {
      transition: {
        staggerChildren: 0.05,
        staggerDirection: -1,
      },
    },
  };

  const itemVariants = {
    open: {
      y: 0,
      opacity: 1,
    },
    closed: {
      y: 50,
      opacity: 0,
    },
  };

  const menuItems = [
    { name: "Kezdőoldal", link: "home" },
    { name: "Visszajelzés", link: "rsvp" },
    { name: "Helyszín", link: "venue" },
    { name: "Menü", link: "menu" },
    { name: "Itallap", link: "beverage" },
    { name: "Forgatókönyv", link: "timeline" },
  ];

  return (
    <motion.div className={styles.links} variants={variants}>
      {menuItems.map((menu) => {
        return (
          <motion.div
            key={menu.name}
            className={styles.a}
            variants={itemVariants}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.95 }}
          >
            <Link
              to={menu.link}
              className={styles.link}
              onClick={() => setOpen((prev: any) => !prev)}
            >
              {menu.name}
            </Link>
          </motion.div>
        );
      })}
    </motion.div>
  );
};

export default Links;
