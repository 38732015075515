import styles from "./ErrorPage.module.css";

export default function ErrorPage() {
  console.log("Ne turkáljááááááá xD");
  return (
    <div className={styles.page} id="error-page">
      <img className={styles.img} src="zoning_cat.png" alt="" />
      <h1 className={styles.title}>404 - Az oldal nem található 🧐</h1>
    </div>
  );
}
