import styles from "./Food.module.css";

const Food = () => {
  return (
    <div id="menu">
      <section>
        <div className="section-container">
          <div className={styles.menu_container}>
            <div className={`${styles.title} section-title`}>Menü</div>
            <img className="title-divider" alt="" src="divider.svg" />
            <div className={styles.foods_container}>
              <div className={styles.food_container}>
                <div className={`${styles.food_title} title`}>Előétel</div>
                <div className={styles.food_description}>
                  <div className={styles.food_name}>
                    Rusztikus padlizsánkrém, cukkini chips
                  </div>
                  <div className={styles.food_name}>
                    Csirkés quesadillas, chipotle salsa
                  </div>
                  <div className={styles.food_name}>
                    Rose kacsamell „carpaccio”, mini ratatouille, cottage cheese
                  </div>
                </div>
              </div>
              <div className={styles.food_container}>
                <div className={`${styles.food_title} title`}>Leves</div>
                <div className={styles.food_description}>
                  <div className={styles.food_name}>Újházy tyúkhúsleves</div>
                </div>
              </div>
              <div className={styles.food_container}>
                <div className={`${styles.food_title} title`}>Főétel</div>
                <div className={styles.food_description}>
                  <div className={styles.food_name}>
                    Fehér morzsában sült sajtfalatok, relish majonéz
                  </div>
                  <div className={styles.food_name}>
                    Fűszerkéregben sült egész szűzpecsenye, gratin burgonya,
                    balzsamos hagymaragu
                  </div>
                  <div className={styles.food_name}>
                    Betyár töltött borda (füstölt tarja, főtt tojás, uborka,
                    mustár) füstölt sajtos rizi-bizi, fűszeres steak burgonya
                  </div>
                  <div className={styles.food_name}>
                    Burgundi marharagu vajas snidlinges galuska
                  </div>
                </div>
              </div>
              <div className={styles.food_container}>
                <div className={`${styles.food_title} title`}>Desszert</div>
                <div className={styles.food_description}>
                  <div className={styles.food_name}>
                    Profiterol (csokis, vaníliás, fehér csokis)
                  </div>
                  <div className={styles.food_name}>
                    Túrókrémes erdei gyümölcsös pohárkrém
                  </div>
                </div>
              </div>
              <div className={styles.food_container}>
                <div className={`${styles.food_title} title`}>Éjféli menü</div>
                <div className={styles.food_description}>
                  <div className={styles.food_name}>Mini töltött káposzta</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Food;
